import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ToastMessage = ({
  messages
}) => {

  const notify = (message, level) => {
    const props = {
      position: "bottom-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false
    }
    if (level == 'success') {
      toast.success(message, props)
    }
    if (level == 'info') {
      toast.info(message, props)
    }
    if (level == 'warning') {
      toast.warning(message, props)
    }
    if (level == 'error') {
      toast.error(message, props)
    }
  }

  useEffect(() => {
    [...document.querySelectorAll(`${messages} > div`)].forEach(e => {
      notify(e.dataset.message, e.dataset.level)
    })
  }, []);

  return <ToastContainer/>;
}

export default ToastMessage;