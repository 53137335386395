import React from "react";
import ReactDOM from "react-dom/client";

import configureWeb from "./configureWeb";
import modal from "./modal";
import tomSelect from "./tomSelect";
import ToastMessage from "./components/toastify/ToastMessage";
import '../css/micromodal.css';


document.addEventListener("DOMContentLoaded", () => {
  configureWeb();
  modal();
  tomSelect();

  const toastAppMessageContainer = document.querySelector("#toast_app_messages")
  if (toastAppMessageContainer !== null) {
    const root = ReactDOM.createRoot(toastAppMessageContainer);
    root.render(<ToastMessage messages={toastAppMessageContainer.dataset.messages}/>);
  }
});