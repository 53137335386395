import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import MicroModal from 'micromodal';

const modal = () => {
  MicroModal.init();
  [...document.querySelectorAll("[data-modal-label]")].forEach(el => {
    el.addEventListener("click", (e) => {
      Confirm.show(
        el.dataset.modalLabel,
        el.dataset.modalContent,
        "Yes",
        "No",
        function okCb() {
          if (typeof el.dataset.modalFormId) {
            document.getElementById(el.dataset.modalFormId).submit();
          }
        },
        function cancelCb() {},
        {
          titleColor: "#000",
          borderRadius: "0px",
          okButtonBackground: "#7AC143"
        }
      );
    })
  })
}

export default modal;