import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import flatpickr from "flatpickr";
import TomSelect from "tom-select/dist/js/tom-select.complete";
import "flatpickr/dist/themes/light.css";


const configureWeb = () => {
  // handle dashboard
  const userDropdown = document.getElementById("toggleUserDropdown");
  if (userDropdown) {
    userDropdown.addEventListener("click", () => {
      document.getElementById(userDropdown.dataset.trigger).classList.toggle("invisible");
    })
  }
  window.onclick = function (event) {
    if (!event.target.matches('.drop-button') && !event.target.matches('.drop-search')) {
      var dropdowns = document.getElementsByClassName("dropdownlist");
      for (var i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (!openDropdown.classList.contains('invisible')) {
          openDropdown.classList.add('invisible');
        }
      }
    }
  };

  const btnSidebarToggle = document.getElementById("btnSidebarToggle");
  if (btnSidebarToggle) {
    btnSidebarToggle.addEventListener("click", () => {
      btnSidebarToggle.classList.toggle("opened");
      document.querySelector("nav .sidebar").classList.toggle("opened");
    });
  }

  // handle conditional showing fields in forms
  const handleConditionalCheckbox = (form, checkbox) => {
    const inputIDs = checkbox.dataset.showWhenChecked.split(",");
    if (checkbox.checked) {
      inputIDs.forEach(inputID => {
        const wrapper = form.querySelector(`#${inputID}`);
        wrapper.classList.remove("hidden");
      })
    } else {
      inputIDs.forEach(inputID => {
        const wrapper = form.querySelector(`#${inputID}`);
        wrapper.classList.add("hidden");
      })
    }
  }
  [...document.querySelectorAll(".custom-form")].forEach(form => {
    form.querySelectorAll("[data-show-when-checked]").forEach(checkbox => {
      handleConditionalCheckbox(form, checkbox);
      checkbox.addEventListener("change", () => {
        handleConditionalCheckbox(form, checkbox);
      })
    })
  });

  // init flatpickr (datetime widget)
  flatpickr(".datetimeinput", {
    enableTime: true,
    dateFormat: "d/m/Y H:i:S",
    minDate: "today"
  });

  // init tippy
  tippy("[data-tippy-content]", {
    allowHTML: true
  });

  // auto submit on change (forms with `submit-on-change` class)
  [...document.querySelectorAll('.submit-on-change')].forEach(form => {
    [...form.querySelectorAll('select')].forEach(select => {
      select.addEventListener('change', () => form.submit());
    })
  })

  // cookies for admin panel sidebar (autohide in iframe)
  document.cookie = "sidebar_pinned=false;path=/";
  const openAdminLink = document.getElementById("openAdminLink");
  if (openAdminLink) {
    openAdminLink.addEventListener("click", () => {
      document.cookie = "sidebar_pinned=true;path=/";
    })
  }

  // handle reset form
  const form = document.querySelector(".uniForm");
  if (form) {
    const resetButton = form.querySelector("[type=reset]");
    if (resetButton) {
      resetButton.addEventListener("click", () => {
        [...form.querySelectorAll(".tom-select-field select")].forEach(el => {
          const select = el.tomselect;
          select.clear();
        });
      });
    }
  }
}

export default configureWeb;