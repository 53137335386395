import TomSelect from "tom-select/dist/js/tom-select.complete";
import "tom-select/dist/css/tom-select.css";

const tomSelect = () => {
  [...document.querySelectorAll(".tom-select-field select")].forEach(el => {
    const controlSelect = new TomSelect(el,{
      plugins: ["dropdown_input"],
      maxOptions: 400
    });
    const initial = document.getElementById("tomselect_initial");
    if (initial && el.name == initial.dataset.name) {
      controlSelect.setValue(initial.dataset.value);
    }
  });
}

export default tomSelect;